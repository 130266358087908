import { default as affiliateszIBqJ1wjgHMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/affiliates.vue?macro=true";
import { default as database_45viewsh0Xwo1DZvgMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/database-views.vue?macro=true";
import { default as discount_45students_45academics_45ngosahoL7me1BrMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/discount-students-academics-ngos.vue?macro=true";
import { default as _91slug_93pM4NF2mBtFMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/features/[slug].vue?macro=true";
import { default as indexvnw7tleSb1Meta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/features/index.vue?macro=true";
import { default as _91slug_934YVrnani4VMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/form-templates/[slug].vue?macro=true";
import { default as indexkA6AjMhR64Meta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/form-templates/index.vue?macro=true";
import { default as _91slug_93pGS7NHcZhhMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/form-templates/industries/[slug].vue?macro=true";
import { default as _91slug_93gMM6LQBGlBMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/form-templates/types/[slug].vue?macro=true";
import { default as edittzJ5ACGCeOMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as index1HoWFcNslTMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/forms/[slug]/index.vue?macro=true";
import { default as analyticsTNpEL1GONlMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/forms/[slug]/show/analytics.vue?macro=true";
import { default as indexC3DLJx6s80Meta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexzx9GW6nuG2Meta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as sharelsfXFXde7mMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as showbazr6ft8WmMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/forms/[slug]/show.vue?macro=true";
import { default as indexJEjBv20HxGMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/forms/create/index.vue?macro=true";
import { default as uploadsvoHM3LXA9lMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/forms/uploads.vue?macro=true";
import { default as guidess5Ng1BLukhMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/guides.vue?macro=true";
import { default as homedfhd4gmsWQMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/home.vue?macro=true";
import { default as indexH5ivNXVALJMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/index.vue?macro=true";
import { default as integrationsvqpobTA787Meta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/integrations.vue?macro=true";
import { default as loginpACDwzBCDNMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/login.vue?macro=true";
import { default as my_45templatesWt4dz4cpokMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/my-templates.vue?macro=true";
import { default as notion_45ambassadors_45influencerszr5ilx66JpMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/notion-ambassadors-influencers.vue?macro=true";
import { default as _91slug_93NjWPoi0hGKMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/notion-form/[slug].vue?macro=true";
import { default as callbackfKNQZYka49Meta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/notion/callback.vue?macro=true";
import { default as guest_45callbackmzxEUBVOlIMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/notion/guest-callback.vue?macro=true";
import { default as callbackwIn5YeHeCmMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/oauth/callback.vue?macro=true";
import { default as partnersQikoC3zOaMMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/partners.vue?macro=true";
import { default as _91token_93DfhS60FOiaMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/password/reset/[token].vue?macro=true";
import { default as index0FCt1f4R2wMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/password/reset/index.vue?macro=true";
import { default as pricingNv4v11ofZaMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/pricing.vue?macro=true";
import { default as privacy_45policyjp6AwrhO6uMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/privacy-policy.vue?macro=true";
import { default as registervBji94vGVdMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/register.vue?macro=true";
import { default as access_45tokenss4C2f1hPJfMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/settings/access-tokens.vue?macro=true";
import { default as accountsIXfyrqg3GMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/settings/account.vue?macro=true";
import { default as adminjEYiCvMbGKMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/settings/admin.vue?macro=true";
import { default as billingbVfytjhAlrMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/settings/billing.vue?macro=true";
import { default as connectionssYC5vDDrlOMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/settings/connections.vue?macro=true";
import { default as custom_45domainC9rg2I79wbMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/settings/custom-domain.vue?macro=true";
import { default as indextxY8vnWIO3Meta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/settings/index.vue?macro=true";
import { default as passwordPo4kvRkySKMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/settings/password.vue?macro=true";
import { default as profileypIjZpal7uMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/settings/profile.vue?macro=true";
import { default as workspacesY6Tm8hdPx1Meta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/settings/workspaces.vue?macro=true";
import { default as settings6f8Cd7kqFuMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/settings.vue?macro=true";
import { default as errorYPGjxjy7X3Meta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/subscriptions/error.vue?macro=true";
import { default as successTBlNza8e2PMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/subscriptions/success.vue?macro=true";
import { default as terms_45conditionsNHYrqFxbxgMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/terms-conditions.vue?macro=true";
import { default as edit6ZoXOnBoA8Meta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/views/[slug]/edit.vue?macro=true";
import { default as index2fRFY6kDmOMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/views/[slug]/index.vue?macro=true";
import { default as index2eRcB8ejTgMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/views/[slug]/show/index.vue?macro=true";
import { default as shareYfjTHEPGbtMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/views/[slug]/show/share.vue?macro=true";
import { default as showRW6UBo3G0aMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/views/[slug]/show.vue?macro=true";
import { default as indexf7uh7kD6bSMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/views/create/index.vue?macro=true";
import { default as indexjDgn2QhHm3Meta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/views/index.vue?macro=true";
import { default as uploadsgGLE9YWLIbMeta } from "/codebuild/output/src3435531623/src/notionforms/client/pages/views/uploads.vue?macro=true";
export default [
  {
    name: affiliateszIBqJ1wjgHMeta?.name ?? "affiliates",
    path: affiliateszIBqJ1wjgHMeta?.path ?? "/affiliates",
    meta: affiliateszIBqJ1wjgHMeta || {},
    alias: affiliateszIBqJ1wjgHMeta?.alias || [],
    redirect: affiliateszIBqJ1wjgHMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/affiliates.vue").then(m => m.default || m)
  },
  {
    name: database_45viewsh0Xwo1DZvgMeta?.name ?? "database-views",
    path: database_45viewsh0Xwo1DZvgMeta?.path ?? "/database-views",
    meta: database_45viewsh0Xwo1DZvgMeta || {},
    alias: database_45viewsh0Xwo1DZvgMeta?.alias || [],
    redirect: database_45viewsh0Xwo1DZvgMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/database-views.vue").then(m => m.default || m)
  },
  {
    name: discount_45students_45academics_45ngosahoL7me1BrMeta?.name ?? "discount-students-academics-ngos",
    path: discount_45students_45academics_45ngosahoL7me1BrMeta?.path ?? "/discount-students-academics-ngos",
    meta: discount_45students_45academics_45ngosahoL7me1BrMeta || {},
    alias: discount_45students_45academics_45ngosahoL7me1BrMeta?.alias || [],
    redirect: discount_45students_45academics_45ngosahoL7me1BrMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/discount-students-academics-ngos.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pM4NF2mBtFMeta?.name ?? "features-slug",
    path: _91slug_93pM4NF2mBtFMeta?.path ?? "/features/:slug()",
    meta: _91slug_93pM4NF2mBtFMeta || {},
    alias: _91slug_93pM4NF2mBtFMeta?.alias || [],
    redirect: _91slug_93pM4NF2mBtFMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/features/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexvnw7tleSb1Meta?.name ?? "features",
    path: indexvnw7tleSb1Meta?.path ?? "/features",
    meta: indexvnw7tleSb1Meta || {},
    alias: indexvnw7tleSb1Meta?.alias || [],
    redirect: indexvnw7tleSb1Meta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_934YVrnani4VMeta?.name ?? "form-templates-slug",
    path: _91slug_934YVrnani4VMeta?.path ?? "/form-templates/:slug()",
    meta: _91slug_934YVrnani4VMeta || {},
    alias: _91slug_934YVrnani4VMeta?.alias || [],
    redirect: _91slug_934YVrnani4VMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/form-templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexkA6AjMhR64Meta?.name ?? "form-templates",
    path: indexkA6AjMhR64Meta?.path ?? "/form-templates",
    meta: indexkA6AjMhR64Meta || {},
    alias: indexkA6AjMhR64Meta?.alias || [],
    redirect: indexkA6AjMhR64Meta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/form-templates/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pGS7NHcZhhMeta?.name ?? "form-templates-industries-slug",
    path: _91slug_93pGS7NHcZhhMeta?.path ?? "/form-templates/industries/:slug()",
    meta: _91slug_93pGS7NHcZhhMeta || {},
    alias: _91slug_93pGS7NHcZhhMeta?.alias || [],
    redirect: _91slug_93pGS7NHcZhhMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/form-templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93gMM6LQBGlBMeta?.name ?? "form-templates-types-slug",
    path: _91slug_93gMM6LQBGlBMeta?.path ?? "/form-templates/types/:slug()",
    meta: _91slug_93gMM6LQBGlBMeta || {},
    alias: _91slug_93gMM6LQBGlBMeta?.alias || [],
    redirect: _91slug_93gMM6LQBGlBMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/form-templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: edittzJ5ACGCeOMeta?.name ?? "forms-slug-edit",
    path: edittzJ5ACGCeOMeta?.path ?? "/forms/:slug()/edit",
    meta: edittzJ5ACGCeOMeta || {},
    alias: edittzJ5ACGCeOMeta?.alias || [],
    redirect: edittzJ5ACGCeOMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: index1HoWFcNslTMeta?.name ?? "forms-slug",
    path: index1HoWFcNslTMeta?.path ?? "/forms/:slug()",
    meta: index1HoWFcNslTMeta || {},
    alias: index1HoWFcNslTMeta?.alias || [],
    redirect: index1HoWFcNslTMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showbazr6ft8WmMeta?.name ?? undefined,
    path: showbazr6ft8WmMeta?.path ?? "/forms/:slug()/show",
    meta: showbazr6ft8WmMeta || {},
    alias: showbazr6ft8WmMeta?.alias || [],
    redirect: showbazr6ft8WmMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: analyticsTNpEL1GONlMeta?.name ?? "forms-slug-show-analytics",
    path: analyticsTNpEL1GONlMeta?.path ?? "analytics",
    meta: analyticsTNpEL1GONlMeta || {},
    alias: analyticsTNpEL1GONlMeta?.alias || [],
    redirect: analyticsTNpEL1GONlMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/forms/[slug]/show/analytics.vue").then(m => m.default || m)
  },
  {
    name: indexC3DLJx6s80Meta?.name ?? "forms-slug-show",
    path: indexC3DLJx6s80Meta?.path ?? "",
    meta: indexC3DLJx6s80Meta || {},
    alias: indexC3DLJx6s80Meta?.alias || [],
    redirect: indexC3DLJx6s80Meta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: indexzx9GW6nuG2Meta?.name ?? "forms-slug-show-integrations",
    path: indexzx9GW6nuG2Meta?.path ?? "integrations",
    meta: indexzx9GW6nuG2Meta || {},
    alias: indexzx9GW6nuG2Meta?.alias || [],
    redirect: indexzx9GW6nuG2Meta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: sharelsfXFXde7mMeta?.name ?? "forms-slug-show-share",
    path: sharelsfXFXde7mMeta?.path ?? "share",
    meta: sharelsfXFXde7mMeta || {},
    alias: sharelsfXFXde7mMeta?.alias || [],
    redirect: sharelsfXFXde7mMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexJEjBv20HxGMeta?.name ?? "forms-create",
    path: indexJEjBv20HxGMeta?.path ?? "/forms/create",
    meta: indexJEjBv20HxGMeta || {},
    alias: indexJEjBv20HxGMeta?.alias || [],
    redirect: indexJEjBv20HxGMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: uploadsvoHM3LXA9lMeta?.name ?? "forms-uploads",
    path: uploadsvoHM3LXA9lMeta?.path ?? "/forms/uploads",
    meta: uploadsvoHM3LXA9lMeta || {},
    alias: uploadsvoHM3LXA9lMeta?.alias || [],
    redirect: uploadsvoHM3LXA9lMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/forms/uploads.vue").then(m => m.default || m)
  },
  {
    name: guidess5Ng1BLukhMeta?.name ?? "guides",
    path: guidess5Ng1BLukhMeta?.path ?? "/guides",
    meta: guidess5Ng1BLukhMeta || {},
    alias: guidess5Ng1BLukhMeta?.alias || [],
    redirect: guidess5Ng1BLukhMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/guides.vue").then(m => m.default || m)
  },
  {
    name: homedfhd4gmsWQMeta?.name ?? "home",
    path: homedfhd4gmsWQMeta?.path ?? "/home",
    meta: homedfhd4gmsWQMeta || {},
    alias: homedfhd4gmsWQMeta?.alias || [],
    redirect: homedfhd4gmsWQMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexH5ivNXVALJMeta?.name ?? "index",
    path: indexH5ivNXVALJMeta?.path ?? "/",
    meta: indexH5ivNXVALJMeta || {},
    alias: indexH5ivNXVALJMeta?.alias || [],
    redirect: indexH5ivNXVALJMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: integrationsvqpobTA787Meta?.name ?? "integrations",
    path: integrationsvqpobTA787Meta?.path ?? "/integrations",
    meta: integrationsvqpobTA787Meta || {},
    alias: integrationsvqpobTA787Meta?.alias || [],
    redirect: integrationsvqpobTA787Meta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/integrations.vue").then(m => m.default || m)
  },
  {
    name: loginpACDwzBCDNMeta?.name ?? "login",
    path: loginpACDwzBCDNMeta?.path ?? "/login",
    meta: loginpACDwzBCDNMeta || {},
    alias: loginpACDwzBCDNMeta?.alias || [],
    redirect: loginpACDwzBCDNMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: my_45templatesWt4dz4cpokMeta?.name ?? "my-templates",
    path: my_45templatesWt4dz4cpokMeta?.path ?? "/my-templates",
    meta: my_45templatesWt4dz4cpokMeta || {},
    alias: my_45templatesWt4dz4cpokMeta?.alias || [],
    redirect: my_45templatesWt4dz4cpokMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/my-templates.vue").then(m => m.default || m)
  },
  {
    name: notion_45ambassadors_45influencerszr5ilx66JpMeta?.name ?? "notion-ambassadors-influencers",
    path: notion_45ambassadors_45influencerszr5ilx66JpMeta?.path ?? "/notion-ambassadors-influencers",
    meta: notion_45ambassadors_45influencerszr5ilx66JpMeta || {},
    alias: notion_45ambassadors_45influencerszr5ilx66JpMeta?.alias || [],
    redirect: notion_45ambassadors_45influencerszr5ilx66JpMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/notion-ambassadors-influencers.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NjWPoi0hGKMeta?.name ?? "notion-form-slug",
    path: _91slug_93NjWPoi0hGKMeta?.path ?? "/notion-form/:slug()",
    meta: _91slug_93NjWPoi0hGKMeta || {},
    alias: _91slug_93NjWPoi0hGKMeta?.alias || [],
    redirect: _91slug_93NjWPoi0hGKMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/notion-form/[slug].vue").then(m => m.default || m)
  },
  {
    name: callbackfKNQZYka49Meta?.name ?? "notion-callback",
    path: callbackfKNQZYka49Meta?.path ?? "/notion/callback",
    meta: callbackfKNQZYka49Meta || {},
    alias: callbackfKNQZYka49Meta?.alias || [],
    redirect: callbackfKNQZYka49Meta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/notion/callback.vue").then(m => m.default || m)
  },
  {
    name: guest_45callbackmzxEUBVOlIMeta?.name ?? "notion-guest-callback",
    path: guest_45callbackmzxEUBVOlIMeta?.path ?? "/notion/guest-callback",
    meta: guest_45callbackmzxEUBVOlIMeta || {},
    alias: guest_45callbackmzxEUBVOlIMeta?.alias || [],
    redirect: guest_45callbackmzxEUBVOlIMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/notion/guest-callback.vue").then(m => m.default || m)
  },
  {
    name: callbackwIn5YeHeCmMeta?.name ?? "oauth-callback",
    path: callbackwIn5YeHeCmMeta?.path ?? "/oauth/callback",
    meta: callbackwIn5YeHeCmMeta || {},
    alias: callbackwIn5YeHeCmMeta?.alias || [],
    redirect: callbackwIn5YeHeCmMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: partnersQikoC3zOaMMeta?.name ?? "partners",
    path: partnersQikoC3zOaMMeta?.path ?? "/partners",
    meta: partnersQikoC3zOaMMeta || {},
    alias: partnersQikoC3zOaMMeta?.alias || [],
    redirect: partnersQikoC3zOaMMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: _91token_93DfhS60FOiaMeta?.name ?? "password-reset-token",
    path: _91token_93DfhS60FOiaMeta?.path ?? "/password/reset/:token()",
    meta: _91token_93DfhS60FOiaMeta || {},
    alias: _91token_93DfhS60FOiaMeta?.alias || [],
    redirect: _91token_93DfhS60FOiaMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: index0FCt1f4R2wMeta?.name ?? "password-reset",
    path: index0FCt1f4R2wMeta?.path ?? "/password/reset",
    meta: index0FCt1f4R2wMeta || {},
    alias: index0FCt1f4R2wMeta?.alias || [],
    redirect: index0FCt1f4R2wMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/password/reset/index.vue").then(m => m.default || m)
  },
  {
    name: pricingNv4v11ofZaMeta?.name ?? "pricing",
    path: pricingNv4v11ofZaMeta?.path ?? "/pricing",
    meta: pricingNv4v11ofZaMeta || {},
    alias: pricingNv4v11ofZaMeta?.alias || [],
    redirect: pricingNv4v11ofZaMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyjp6AwrhO6uMeta?.name ?? "privacy-policy",
    path: privacy_45policyjp6AwrhO6uMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyjp6AwrhO6uMeta || {},
    alias: privacy_45policyjp6AwrhO6uMeta?.alias || [],
    redirect: privacy_45policyjp6AwrhO6uMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: registervBji94vGVdMeta?.name ?? "register",
    path: registervBji94vGVdMeta?.path ?? "/register",
    meta: registervBji94vGVdMeta || {},
    alias: registervBji94vGVdMeta?.alias || [],
    redirect: registervBji94vGVdMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settings6f8Cd7kqFuMeta?.name ?? undefined,
    path: settings6f8Cd7kqFuMeta?.path ?? "/settings",
    meta: settings6f8Cd7kqFuMeta || {},
    alias: settings6f8Cd7kqFuMeta?.alias || [],
    redirect: settings6f8Cd7kqFuMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: access_45tokenss4C2f1hPJfMeta?.name ?? "settings-access-tokens",
    path: access_45tokenss4C2f1hPJfMeta?.path ?? "access-tokens",
    meta: access_45tokenss4C2f1hPJfMeta || {},
    alias: access_45tokenss4C2f1hPJfMeta?.alias || [],
    redirect: access_45tokenss4C2f1hPJfMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: accountsIXfyrqg3GMeta?.name ?? "settings-account",
    path: accountsIXfyrqg3GMeta?.path ?? "account",
    meta: accountsIXfyrqg3GMeta || {},
    alias: accountsIXfyrqg3GMeta?.alias || [],
    redirect: accountsIXfyrqg3GMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: adminjEYiCvMbGKMeta?.name ?? "settings-admin",
    path: adminjEYiCvMbGKMeta?.path ?? "admin",
    meta: adminjEYiCvMbGKMeta || {},
    alias: adminjEYiCvMbGKMeta?.alias || [],
    redirect: adminjEYiCvMbGKMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: billingbVfytjhAlrMeta?.name ?? "settings-billing",
    path: billingbVfytjhAlrMeta?.path ?? "billing",
    meta: billingbVfytjhAlrMeta || {},
    alias: billingbVfytjhAlrMeta?.alias || [],
    redirect: billingbVfytjhAlrMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: connectionssYC5vDDrlOMeta?.name ?? "settings-connections",
    path: connectionssYC5vDDrlOMeta?.path ?? "connections",
    meta: connectionssYC5vDDrlOMeta || {},
    alias: connectionssYC5vDDrlOMeta?.alias || [],
    redirect: connectionssYC5vDDrlOMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: custom_45domainC9rg2I79wbMeta?.name ?? "settings-custom-domain",
    path: custom_45domainC9rg2I79wbMeta?.path ?? "custom-domain",
    meta: custom_45domainC9rg2I79wbMeta || {},
    alias: custom_45domainC9rg2I79wbMeta?.alias || [],
    redirect: custom_45domainC9rg2I79wbMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/settings/custom-domain.vue").then(m => m.default || m)
  },
  {
    name: indextxY8vnWIO3Meta?.name ?? "settings",
    path: indextxY8vnWIO3Meta?.path ?? "",
    meta: indextxY8vnWIO3Meta || {},
    alias: indextxY8vnWIO3Meta?.alias || [],
    redirect: indextxY8vnWIO3Meta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: passwordPo4kvRkySKMeta?.name ?? "settings-password",
    path: passwordPo4kvRkySKMeta?.path ?? "password",
    meta: passwordPo4kvRkySKMeta || {},
    alias: passwordPo4kvRkySKMeta?.alias || [],
    redirect: passwordPo4kvRkySKMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: profileypIjZpal7uMeta?.name ?? "settings-profile",
    path: profileypIjZpal7uMeta?.path ?? "profile",
    meta: profileypIjZpal7uMeta || {},
    alias: profileypIjZpal7uMeta?.alias || [],
    redirect: profileypIjZpal7uMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: workspacesY6Tm8hdPx1Meta?.name ?? "settings-workspaces",
    path: workspacesY6Tm8hdPx1Meta?.path ?? "workspaces",
    meta: workspacesY6Tm8hdPx1Meta || {},
    alias: workspacesY6Tm8hdPx1Meta?.alias || [],
    redirect: workspacesY6Tm8hdPx1Meta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/settings/workspaces.vue").then(m => m.default || m)
  }
]
  },
  {
    name: errorYPGjxjy7X3Meta?.name ?? "subscriptions-error",
    path: errorYPGjxjy7X3Meta?.path ?? "/subscriptions/error",
    meta: errorYPGjxjy7X3Meta || {},
    alias: errorYPGjxjy7X3Meta?.alias || [],
    redirect: errorYPGjxjy7X3Meta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: successTBlNza8e2PMeta?.name ?? "subscriptions-success",
    path: successTBlNza8e2PMeta?.path ?? "/subscriptions/success",
    meta: successTBlNza8e2PMeta || {},
    alias: successTBlNza8e2PMeta?.alias || [],
    redirect: successTBlNza8e2PMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsNHYrqFxbxgMeta?.name ?? "terms-conditions",
    path: terms_45conditionsNHYrqFxbxgMeta?.path ?? "/terms-conditions",
    meta: terms_45conditionsNHYrqFxbxgMeta || {},
    alias: terms_45conditionsNHYrqFxbxgMeta?.alias || [],
    redirect: terms_45conditionsNHYrqFxbxgMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: edit6ZoXOnBoA8Meta?.name ?? "views-slug-edit",
    path: edit6ZoXOnBoA8Meta?.path ?? "/views/:slug()/edit",
    meta: edit6ZoXOnBoA8Meta || {},
    alias: edit6ZoXOnBoA8Meta?.alias || [],
    redirect: edit6ZoXOnBoA8Meta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/views/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: index2fRFY6kDmOMeta?.name ?? "views-slug",
    path: index2fRFY6kDmOMeta?.path ?? "/views/:slug()",
    meta: index2fRFY6kDmOMeta || {},
    alias: index2fRFY6kDmOMeta?.alias || [],
    redirect: index2fRFY6kDmOMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/views/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showRW6UBo3G0aMeta?.name ?? undefined,
    path: showRW6UBo3G0aMeta?.path ?? "/views/:slug()/show",
    meta: showRW6UBo3G0aMeta || {},
    alias: showRW6UBo3G0aMeta?.alias || [],
    redirect: showRW6UBo3G0aMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/views/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: index2eRcB8ejTgMeta?.name ?? "views-slug-show",
    path: index2eRcB8ejTgMeta?.path ?? "",
    meta: index2eRcB8ejTgMeta || {},
    alias: index2eRcB8ejTgMeta?.alias || [],
    redirect: index2eRcB8ejTgMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/views/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: shareYfjTHEPGbtMeta?.name ?? "views-slug-show-share",
    path: shareYfjTHEPGbtMeta?.path ?? "share",
    meta: shareYfjTHEPGbtMeta || {},
    alias: shareYfjTHEPGbtMeta?.alias || [],
    redirect: shareYfjTHEPGbtMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/views/[slug]/show/share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexf7uh7kD6bSMeta?.name ?? "views-create",
    path: indexf7uh7kD6bSMeta?.path ?? "/views/create",
    meta: indexf7uh7kD6bSMeta || {},
    alias: indexf7uh7kD6bSMeta?.alias || [],
    redirect: indexf7uh7kD6bSMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/views/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexjDgn2QhHm3Meta?.name ?? "views",
    path: indexjDgn2QhHm3Meta?.path ?? "/views",
    meta: indexjDgn2QhHm3Meta || {},
    alias: indexjDgn2QhHm3Meta?.alias || [],
    redirect: indexjDgn2QhHm3Meta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/views/index.vue").then(m => m.default || m)
  },
  {
    name: uploadsgGLE9YWLIbMeta?.name ?? "views-uploads",
    path: uploadsgGLE9YWLIbMeta?.path ?? "/views/uploads",
    meta: uploadsgGLE9YWLIbMeta || {},
    alias: uploadsgGLE9YWLIbMeta?.alias || [],
    redirect: uploadsgGLE9YWLIbMeta?.redirect,
    component: () => import("/codebuild/output/src3435531623/src/notionforms/client/pages/views/uploads.vue").then(m => m.default || m)
  }
]